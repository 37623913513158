import styled from 'styled-components'
import { breakpoints } from 'src/utils/styles/global-styles'
import { GatsbyImage } from 'gatsby-plugin-image'
import {
  SaveInfo,
  ProductSelectionHeaderRow,
  DropDownContainerOptions,
} from 'src/templates/CalendarProductPage/styles'

export const Container = styled.div`
  border-top: ${(props) => (props.line ? '1px solid #d5dee5' : 'none')};
  border-bottom: ${(props) => (props.line ? '1px solid #d5dee5' : 'none')};
  padding: 16px 0px 0px;
  margin: 32px 0px 24px;
  @media (max-width: ${breakpoints.s}px) {
    text-align: ${(props) => (props.line ? 'center' : 'left')};
    margin: ${(props) => (props.line ? '32px 0px 24px' : '32px 0px -24px')};

    h3,
    section,
    p,
    label {
      margin-left: 0px;
    }
  }
`
export const Header = styled.p`
  color: ${(props) => props.theme.golden};
  font-family: 'FilsonProBold' !important;
  font-style: normal;
  font-weight: 700;
  line-height: 14px;
  margin-bottom: 8px;
`
export const BundleHeader = styled(SaveInfo)`
  margin: ${(props) => (props.line ? '8px 0px 8px 40px' : '0px')};
  font-size: ${(props) => (props.line ? '22px' : '20px')};
`
export const LimitedTime = styled.p`
  font-size: ${(props) => (props.line ? '13px' : '16px')};
  font-family: ${(props) => (props.line ? 'FilsonProRegularItalic' : 'FilsonPro')} !important;
  line-height: 1.3;
  max-width: 520px;
  margin: ${(props) => (props.line ? '6px 0px 13px' : '8px 0px')};
  @media (max-width: ${breakpoints.l}px) {
    max-width: 100%;
  }
  @media (max-width: ${breakpoints.s}px) {
    font-size: 13px;
  }
`
export const CheckboxContainer = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-left: ${(props) => (props.line ? '40px' : '0px')};
  margin-bottom: 24px;
  @media (max-width: ${breakpoints.l}px) {
    margin-bottom: ${(props) => (props.checked && props.line ? '0px' : '24px')};
  }
  @media (max-width: ${breakpoints.s}px) {
    display: inline-flex;
  }
`

export const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  opacity: 0;
  position: absolute;
  margin: 0;
  cursor: pointer;
`

export const StyledCheckbox = styled.div`
  width: 20px;
  height: 20px;
  background: 'transparent';
  border: 1px solid ${(props) => props.theme.grayDark};
  transition: all 150ms;
  cursor: pointer;
`

export const CheckboxLabel = styled.span`
  margin-left: ${(props) => (props.golden ? '4px' : '8px')};
  font-family: 'FilsonPro' !important;
  color: ${(props) => (props.golden ? props.theme.golden : props.theme.grayDark)};
`
export const Check = styled(GatsbyImage)`
  margin-left: -19px;
  margin-right: 1px;
`
export const SpringProductSelectionHeaderRow = styled(ProductSelectionHeaderRow)`
  @media (max-width: ${breakpoints.s}px) {
    align-items: ${(props) => (props.line ? 'center' : 'flex-start')};
  }
`

export const Frame = styled(GatsbyImage)`
  display: none;
  width: 200px;
  @media (max-width: ${breakpoints.l}px) {
    display: block;
    margin: 0 auto 8px;
  }
`
export const PricePoint = styled.p`
  font-size: 22px;
  font-family: 'FilsonProBook';
  font-weight: 300;
  line-height: 26px;
  letter-spacing: 0.1px;
  margin: 0;
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.coral};
  strike {
    color: ${(props) => props.theme.grayLight};
    padding-right: 3px;
    font-size: 18px;
  }
  @media (max-width: 1250px) {
    font-size: 20px;
    strike {
      font-size: 16px;
    }
  }
  @media (max-width: ${breakpoints.l}px) {
    font-size: 22px;
    strike {
      font-size: 18px;
    }
  }
`
