export const CAL_PLUS_FEATURES = {
  customizedScreensaver: {
    heading: 'Photo Screensaver',
    text: `Turn your Calendar into a photo frame when it's not in use! Upload photos with our app.`,
  },
  mealPlanning: {
    heading: 'Meal Planning',
    text: `Never answer, “What are we eating?” again! Plan the week's meals in just 5 minutes.`,
  },
  magicImport: {
    heading: 'Magic Import',
    text: `Forward any email or PDF from school and we’ll convert it into events on your calendar.`,
  },
}
export const PRODUCT_DROPDOWN_LABELS = {
  27: {
    label: `27" Calendar Max`,
  },
  15: {
    label: `15" Calendar`,
  },
  10: {
    label: `10" Calendar`,
  },
}
