import React from 'react'
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next'

import PropTypes from 'prop-types'
import { graphql, useStaticQuery } from 'gatsby'
import { CalendarSyncsSection, Container, Card, Icon, Text } from './styles'
import { Header } from '../Counter/styles'

function Content(props) {
  const { image, alt, text, isCalProduct } = props
  return (
    <Card isCalProduct={isCalProduct}>
      <Icon image={image.childImageSharp.gatsbyImageData} alt={alt} isCalProduct={isCalProduct} />
      <Text>{text}</Text>
    </Card>
  )
}

const CalendarSyncs = ({ title, page, bgColor }) => {
  const { t } = useTranslation('common')

  const { google, outlook, apple, cozi, yahoo } = useStaticQuery(graphql`
    query CalendarSyncsQuery {
      google: file(relativePath: { eq: "calendar/syncs/google-cal.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
        }
      }
      outlook: file(relativePath: { eq: "calendar/syncs/outlook-cal.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
        }
      }
      apple: file(relativePath: { eq: "calendar/syncs/apple-cal.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
        }
      }
      cozi: file(relativePath: { eq: "calendar/syncs/cozi-cal.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
        }
      }
      yahoo: file(relativePath: { eq: "calendar/syncs/yahoo-cal.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
        }
      }
    }
  `)

  const CALENDAR_SYNCS_CONSTANTS = [
    {
      image: google,
      alt: 'Google Calendar Logo',
      text: `Google`,
    },
    {
      image: outlook,
      alt: 'Outlook Calendar Logo',
      text: 'Outlook',
    },
    {
      image: apple,
      alt: 'Apple Calendar Logo',
      text: 'Apple',
    },
    {
      image: cozi,
      alt: 'Cozi Calendar Logo',
      text: 'Cozi',
    },
    {
      image: yahoo,
      alt: 'Yahoo Calendar Logo',
      text: 'Yahoo',
    },
  ]

  const isCalProduct = page === 'calendar-product'
  return (
    <CalendarSyncsSection isCalProduct={isCalProduct} bgColor={bgColor}>
      <Header>
        <Trans>{title || 'Seamlessly auto-syncs with:'}</Trans>
      </Header>
      <Container isCalProduct={isCalProduct}>
        {CALENDAR_SYNCS_CONSTANTS.map((calendar, index) => (
          <Content
            key={index}
            image={calendar.image}
            alt={calendar.alt}
            text={t(calendar.text)}
            isCalProduct={isCalProduct}
          />
        ))}
      </Container>
    </CalendarSyncsSection>
  )
}

Content.propTypes = {
  image: PropTypes.object,
  alt: PropTypes.string,
  text: PropTypes.string,
  isCalProduct: PropTypes.bool,
}

CalendarSyncs.propTypes = {
  title: PropTypes.string,
  page: PropTypes.string,
  bgColor: PropTypes.string,
}

export default CalendarSyncs
