import React, { useState } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'

import PropTypes from 'prop-types'
import {
  DropDownContainer,
  DropDownLabel,
  DropDownLabelOption,
  DropDownImageContainer,
  DropDownIconContainer,
  ProductSelectionHeader,
  PriceAndExplainerContainer,
  ClickableProductSelectionPricePoint,
} from 'src/templates/CalendarProductPage/styles'
import { FormattedCurrency } from '../../utils/FormattedCurrency'
import {
  Container,
  Header,
  BundleHeader,
  LimitedTime,
  CheckboxContainer,
  HiddenCheckbox,
  StyledCheckbox,
  CheckboxLabel,
  Check,
  SpringProductSelectionHeaderRow,
  Frame,
  PricePoint,
} from './styles'
import { LOCALIZATION, TIMEZONES } from '../../utils/constants'

const SpringSale = ({ handleCheckboxChange, checked }) => {
  const { blackFrame, check } = useStaticQuery(graphql`
    query SpringSaleIconsQuery {
      blackFrame: file(relativePath: { eq: "black-frame-bundle.webp" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: NONE)
        }
      }
      check: file(relativePath: { eq: "check.png" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FIXED, placeholder: NONE, width: 18)
        }
      }
    }
  `)

  const prices = {
    'en-ca': {
      original: '199.99',
      discounted: '119.99',
    },
    'en-au': {
      original: '249.00',
      discounted: '149.00',
    },
    'en-gb': {
      original: '159.00',
      discounted: '95.00',
    },
  }
  return (
    <Container checked={checked} line>
      <Header>BUY ONE, GET ONE 40% OFF</Header>
      <SpringProductSelectionHeaderRow line>
        <ProductSelectionHeader>
          <BundleHeader line>Bundle with a 10” Frame</BundleHeader>
        </ProductSelectionHeader>
        <PriceAndExplainerContainer>
          <PricePoint red>
            <strike>{FormattedCurrency(prices[LOCALIZATION].original)}</strike>{' '}
            {FormattedCurrency(prices[LOCALIZATION].discounted)}
          </PricePoint>
        </PriceAndExplainerContainer>
      </SpringProductSelectionHeaderRow>
      <LimitedTime line>
        For a limited time, give yourself the gift of organisation and give a loved one the gift of
        memories. Offer ends 11/11, 23:59 {TIMEZONES[LOCALIZATION]}.
      </LimitedTime>
      <CheckboxContainer checked={checked} line>
        <HiddenCheckbox checked={checked} onChange={() => handleCheckboxChange(!checked)} />
        <StyledCheckbox checked={checked} />
        {checked && <Check image={check.childImageSharp.gatsbyImageData} alt="Checkmark" />}
        <CheckboxLabel>Add a Frame for</CheckboxLabel>
        <CheckboxLabel golden>{FormattedCurrency(prices[LOCALIZATION].discounted)}</CheckboxLabel>
      </CheckboxContainer>
      {checked && (
        <Frame image={blackFrame.childImageSharp.gatsbyImageData} alt="Black Skylight Frame" />
      )}
    </Container>
  )
}

export default SpringSale

SpringSale.propTypes = {
  handleCheckboxChange: PropTypes.func,
  checked: PropTypes.bool,
}
