import React from 'react'
import PropTypes from 'prop-types'

const YotpoReviews = ({ productId, name, link }) => (
  <div
    className="yotpo yotpo-main-widget"
    data-product-id={productId}
    data-name={name}
    data-url={link}
    data-image-url=" // cdn.shopify.com/s/files/1/1353/3805/products/Skylight-Plus-at-Home-resized_large.jpg%3Fv=1561493122"
    data-description='&lt;meta charset="utf-8"&gt;&lt;meta charset="utf-8"&gt;&lt;meta charset="utf-8"&gt;&lt;meta charset="utf-8"&gt;
    &lt;p&gt;&lt;strong&gt;The Skylight Frame has a gorgeous, 10&#39;&#39; color touch screen that displays your photos with extraordinary clarity, color, and brilliance. It is the perfect size for a desk, mantel, coffee table, or kitchen. Skylight is a striking gift that will make an unforgettable impression the moment your loved one turns it on!&lt;/strong&gt;&lt;/p&gt;
    &lt;meta charset="utf-8"&gt;&lt;meta charset="utf-8"&gt;&lt;meta charset="utf-8"&gt;
    &lt;p&gt;&lt;span&gt;Skylight is a beautiful touch-screen, Wi-Fi connected digital photo frame that lets you email photos from your phone or computer directly onto your loved one&#39;s Skylight - from anywhere in the world! &lt;/span&gt;&lt;/p&gt;
    &lt;p&gt;&lt;strong&gt;100% Satisfaction Guaranteed.&lt;/strong&gt; If you don&#39;t love your Skylight, we&#39;ll offer you a full refund.&lt;/p&gt;
    &lt;p&gt;&lt;strong&gt;Delivery Time: &lt;/strong&gt;3-5 business days&lt;br&gt;&lt;br&gt;&lt;span&gt;&lt;/span&gt;&lt;span&gt;&lt;/span&gt;PRODUCT DETAILS:&lt;br&gt;&lt;span&gt;-&lt;strong&gt;Screen size:&lt;/strong&gt; 10&#39;&#39; Diagonal&lt;br&gt;-&lt;strong&gt;Resolution:&lt;/strong&gt; 1280x800 IPS display&lt;br&gt;-&lt;strong&gt;Dimensions:&lt;/strong&gt; 10.7&#39;&#39; x 7.4&#39;&#39; x 0.9&#39;&#39;&lt;br&gt;-&lt;strong&gt;Time required to receive photos:&lt;/strong&gt; &amp;lt;60 seconds&lt;br&gt;-&lt;strong&gt;Accepted digital photo file formats:&lt;/strong&gt; JPEG, BMP, PNG, GIF&lt;br&gt;-&lt;strong&gt;Storage space on device:&lt;/strong&gt; 8GB (enough for 8000+ photos)&lt;br&gt;-&lt;strong&gt;100% Satisfaction Guaranteed&lt;/strong&gt;: If you don&#39;t love your Skylight, we&#39;ll offer you a full refund.&lt;br&gt;&lt;br&gt;&lt;/span&gt;*International users: Outlet adapter not included. Skylight comes with a US-style plug by default.&lt;/p&gt;
    &lt;div id="content-region" class="celery-modal-body"&gt;
    &lt;div class="page-product"&gt;
    &lt;div class="ProductCard" data-id="5345b06ee78aca040003e1a9"&gt;&lt;/div&gt;
    &lt;/div&gt;
    &lt;/div&gt;'
  />
)
YotpoReviews.propTypes = {
  productId: PropTypes.string,
  name: PropTypes.string,
  link: PropTypes.string,
}
export default YotpoReviews
